import { api } from '..';
import parseResponse from './parseResponse';
export const getMetabaseDashboardUrl = dashboardId => parseResponse(api.quality.getMetabaseDashboardUrl(dashboardId));
export const getUnmappedProjects = () => parseResponse(api.quality.getUnmappedProjects());
export const updateProjectTypes = projectTypes => parseResponse(api.quality.updateProjectTypes(projectTypes));
export const getProjectsWithReport = (limit = 100) => parseResponse(api.quality.getProjectsWithReport(limit));
export const createSpellcheck = (projectId, prompt, max_tokens, temperature, top_p) => parseResponse(api.quality.createSpellcheck(projectId, prompt, max_tokens, temperature, top_p));
export const getSpellcheckConfig = () => parseResponse(api.quality.getSpellcheckConfig());
export const createSpellcheckConfig = config => parseResponse(api.quality.createSpellcheckConfig({
  config: config
}));